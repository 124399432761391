import * as React from "react"
import Layout from "../components/layout"
import SEO from "../components/SEO"
import { seoData } from "../shared/seoData"
import MentoringOffering from "../components/Mentoring/MentoringOffering"
import { TeamMembersData } from "../shared/constants"
import { PageTitles } from "../shared/constants"

export default class ReactFundamentals extends React.Component {
  render() {
    const seo = seoData.reactFundamentals
    return (
      <>
        <SEO
          title={seo.title}
          description={seo.metaDescription}
          pathname={seo.pathname}
        />
        <Layout selectedTabValue={false}>
          <MentoringOffering title={PageTitles.reactFundamentals.title} instructors={TeamMembersData} topics={topics}/>
        </Layout>
      </>
    )
  }
}

const topics = [
  "Hands-on experience with JSX syntax",
  "Understanding and practicing Higher-Order Components",
  "Understanding of Class Components and Stateless (Function) Components",
  "Leveraging Props to pass data and validation using PropTypes",
  "Working with data in components using State",
  "Executing API calls to backend servers inside Components",
  "Deep understanding of Component lifecycle methods",
  "Performing Conditional Rendering",
  "Understanding React Hooks",
  "Working with Hooks such as useState, useEffect, useRef, and more",
  "Working with Render Props",
  "Using React Dev Tools for debugging",
]
